import React from "react";
import { useTranslation } from "react-i18next"
import Img from "gatsby-image"
import './luxury-car.scss'
import InstagramWidget from "../InstagramWidget"
import SocialShare from "../SocialShare"
import QuestionBubble from "../QuestionBubble"
import Heading from "../Heading"
import SmartForm from "../SmartForm"

export default ({ data, instaFeed, content}) => {

    const { t } = useTranslation()
    const { cars } = data

    return(
        <div id="luxury-car" className="blog-area blog-no-sidebar pt-50 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog-post-item ml-auto mr-auto">
                        <div className="blog-wrapper blog-details">
                            <div className="blog-content">
                                <Heading title={data.pageTitle} subtitle={data.pageSubtitle} />
                                <div dangerouslySetInnerHTML={{__html: content}} />
                                <h2>{t('luxury_cars_for_rent')}</h2>
                                <div className="car-list-item-box row">
                                    {cars.map((car) => (
                                        <div className="col pull-left car-list-item-wrapper">
                                            <div className="car-list-item">
                                                <div className="img">
                                                    <Img sizes={car.image.childImageSharp.fluid} />
                                                </div>
                                                <div className="car-list-content">
                                                    <div className="col">
                                                        <h3>{car.make}</h3>
                                                        <div className="car-details">
                                                            <h5>{car.model}</h5>
                                                            <h5>{t('rental-rates')}: </h5>
                                                            <ul className="extras">
                                                                <li><p><i className="fa fa-plane"></i> <strong>{t('airportTransfer')}: </strong> {car.airportTransfer}</p></li>
                                                                <li><p><i className="fa fa-bullseye"></i> <strong>{t('rentBudapest')}: </strong> {car.rentBudapest}</p></li>
                                                                <li><p><i className="fa fa-ellipsis-h"></i> <strong>{t('rentCountryside')}: </strong> {car.rentCountryside}</p></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <blockquote class="mb-50">
                                    {data.blockquote}
                                </blockquote>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="important-notes mb-50">
                                <h3>{t('notice')}</h3>
                                    <ul>
                                        <li><i className="fa fa-user"></i><span>{t('only-with-driver')}</span></li>
                                        <li><i className="fa fa-fire-extinguisher"></i><span>{t('no-smoking-in-car')}</span></li>
                                        <li><i className="fa fa-clock-o"></i><span>{t('minimum-rent-period')}</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <QuestionBubble 
                                    title={data.questionTitle} 
                                    description={data.questionDescription} 
                                    phone={data.questionPhone}  
                                    email={data.questionEmail} />
                            </div>
                        </div>
                        <div className="question-area">
                            <h3>{t('would_you_like_to_request_an_offer?')}</h3>
                            <SmartForm 
                                successTitle={t('your_offer_request_has_been_sent_successfully')}
                                successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                formClassName="luxury-car-form" 
                                formName="luxury-car" 
                                mailSubject="Luxusautó bérlés ajánlatkérés"
                                stateProps={["name", "email", "phone", "message"]}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-input">
                                            <label>{t('my_name')}</label>
                                            <input name="name" type="text" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_email')}</label>
                                            <input name="email" type="email" required />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_phone')}</label>
                                            <input name="phone" type="phone" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input">
                                            <label>{t('offer_request')}</label>
                                            <textarea className="form-control" name="message" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input">
                                            <button className="sent-btn" type="submit">
                                                {t('request_offer')}
                                            </button>
                                        </div>
                                    </div>                                   
                                </div>
                            </SmartForm>
                        </div>
                        <div className="common-tag-and-next-prev">
                            <SocialShare title={data.title} hashtags="car,luxury-car"/>
                        </div>
                        <div className="row w-100">
                            <div className="widget mt-60">
                                <InstagramWidget data={instaFeed} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}